<template>
  <div class="wallet">
    <page-nav title="红包奖励" />
    <div class="wallet-pannel">
      
    </div>
  </div>
</template>

<script>
import PageNav from '../../components/PageNav.vue';
export default {
  name: 'Wallet',
  data() {
    return {
      loading: false,
      list: [],
    };
  },
  components: { PageNav },
}
</script>